var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          { class: `${_vm.$screen.width <= 576 ? "container-mobile" : ""}` },
          [
            _c(
              "b-row",
              { staticClass: "tab-onboarding" },
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: {
                          value: _vm.onboardingTabs,
                          cards: "",
                          "active-nav-item-class": "font-weight-bold",
                          "active-tab-class": "font-weight-bold",
                        },
                      },
                      [
                        _c(
                          "b-tab",
                          {
                            staticClass: "pt-0",
                            attrs: { title: _vm.FormMSG(2, "Invitations") },
                          },
                          [_c("b-card-text", [_c("invitations")], 1)],
                          1
                        ),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.FormMSG(3, "Follow up") } },
                          [
                            _c(
                              "b-card-text",
                              [
                                _c("follow-up", {
                                  attrs: {
                                    "for-validation": false,
                                    "follow-ups-list": _vm.followUps,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }