<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div :class="`${$screen.width <= 576 ? 'container-mobile' : ''}`">
				<!-- <b-row>
					<b-col sm="12">
						<h1 :class="`${!$screen.sm ? 'main-page-title' : ''}`">
							{{ FormMSG(1, 'Onboarding') }}
						</h1>
					</b-col>
				</b-row> -->
				<b-row class="tab-onboarding">
					<b-col>
						<b-tabs :value="onboardingTabs" cards active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
							<b-tab :title="FormMSG(2, 'Invitations')" class="pt-0">
								<b-card-text>
									<invitations />
								</b-card-text>
							</b-tab>
							<b-tab :title="FormMSG(3, 'Follow up')">
								<b-card-text>
									<follow-up :for-validation="false" :follow-ups-list="followUps" />
								</b-card-text>
							</b-tab>
						</b-tabs>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import Invitations from '@/modules/onboarding/components/Invitations';
import FollowUp from '@/modules/onboarding/components/FollowUp';
import languageMessages from '@/mixins/languageMessages';
import { getFollowUpList } from '@/modules/onboarding/cruds/onboarding.crud';

export default {
	name: 'OnBoarding',

	mixins: [languageMessages],

	components: {
		Invitations,
		FollowUp
	},

	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			onboardingTabs: 0,
			followUps: []
		};
	},

	async mounted() {
		await this.getFollowUpsList();
	},

	methods: {
		async getFollowUpsList() {
			const result = await getFollowUpList();
			this.followUps = result;
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-onboarding {
	background-color: #ffffff;
	border-radius: 2px;
}
</style>
