<template>
	<div>
		<b-row v-if="$screen.width >= 992" class="form">
			<b-col sm="12" md="12" lg="6" xl="6">
				<b-input-group v-if="$screen.width >= 992">
					<b-form-input type="text" v-model="filter" :placeholder="this.FormMSG(2, 'Type to Search')" @change="handleChangeFilter" />
					<b-input-group-append class="cursor-pointer">
						<b-input-group-text class="btn-search">
							<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
							<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="handleChangeFilter('')" v-else />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col>
				<CardListBuilder v-if="$screen.width < 992" :items="users" :fields="userRequestFields" :omitted-fields="['actions']">
					<template slot="actions" slot-scope="data">
						<b-button variant="primary" size="sm" class="btn bg-transparent border-0" @click="handleClickEditUserRequest(data.item)">
							<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
						</b-button>
						<b-button class="btn bg-transparent border-0" variant="none" size="sm" @click="handleClickDeleteUserRequest(data.item)">
							<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
						</b-button>
					</template>
				</CardListBuilder>
				<b-table
					v-if="$screen.width >= 992"
					hover
					responsive
					selectable
					:selected-variant="selectedColor"
					:select-mode="selectMode"
					:items="users"
					sort-by="name"
					:filter="filter"
					:fields="userRequestFields"
					sticky-header="300px"
					:head-variant="hv"
					bordered
					small
					show-empty
					:empty-text="FormMSG(25, 'No invitation found')"
				>
					<template v-slot:cell(actions)="data">
						<b-button variant="none" size="sm" class="mr-2" @click="handleClickEditUserRequest(data.item)">
							<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
						</b-button>
						<b-button variant="none" size="sm" @click="handleClickDeleteUserRequest(data.item)">
							<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
						</b-button>
					</template>
				</b-table>
			</b-col>
		</b-row>
		<b-modal
			v-model="showUserModal"
			header-class="header-class-modal-doc-package"
			hide-header-close
			:title="FormMSG(354, 'Update invitation user')"
			size="xxl"
			centered
			no-close-on-backdrop
			no-close-on-esc
			cancel-variant="light"
		>
			<user-form
				ref="update-invitation"
				:display-add-btn="false"
				:projectId="projectId"
				:userEntryLevel="userEntryLevel"
				:edit-data="editData"
				v-on:closeForm="handleCloseForm"
				@user-form:success="handleUserFormSuccess"
				@user-form:watingAddUpdProject="handleWatingAddUpdProject"
			/>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-end align-items-center">
					<b-col sm="4" md="4" lg="2" xl="2">
						<b-button size="md" variant="light" @click="okAfterUserEdit" block>
							{{ FormMSG(351, 'Close') }}
						</b-button>
					</b-col>
					<b-col sm="8" md="8" lg="3" xl="3">
						<b-button size="md" variant="primary" :disabled="watingAddProject" @click="dispatchEvent" block>
							<div class="d-flex justify-content-center align-items-center">
								<b-spinner v-show="watingAddProject" small />
								<div class="pl-2" style="margin-top: 1px">{{ FormMSG(3, 'Update invitation') }}</div>
							</div>
						</b-button>
					</b-col>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import UserForm from '@/views/users/UserForm';
import { getUserRequestInfo } from '@/cruds/users.crud';
import { store } from '@/store';
import { Search, X } from 'lucide-vue';
import moment from 'moment';
import { GetEncodingValidation } from '../../../cruds/users.crud';

export default {
	name: 'InvitationList',
	mixins: [languageMessages, globalMixin],
	props: {
		userRequests: { type: Array, required: true },
		projectId: { type: [Number, String], required: true }
	},
	components: {
		UserForm,
		Search,
		X
	},
	data() {
		return {
			navigatorLang: this.navigatorLanguage(),
			hv: 'dark',
			users: [],
			filter: '',
			showUserModal: false,
			editData: null,
			watingAddProject: false,
			selectMode: 'single',
			selectedColor: 'primary',
			userEntryLevel: {}
		};
	},
	watch: {
		userRequests: {
			handler(val) {
				this.users = [];
				val.map((v) => {
					this.users.push(v);
				});
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		userRequestFields() {
			var ret = [];
			ret = [
				// {key: 'id'},
				{
					label: this.FormMSG(20, 'Last name'),
					key: 'userName',
					sortable: true
				},
				{
					label: this.FormMSG(21, 'First name'),
					key: 'userFirstName',
					sortable: true
				},
				{
					label: this.FormMSG(22, 'Email'),
					key: 'email',
					sortable: true
				},
				{
					key: 'createdAt',
					label: this.FormMSG(54, 'Sent on'),
					sortable: true,
					formatter: (value) => {
						if (!this.country12HList.includes(this.navigatorLang)) {
							return moment(value).local().format('DD/MM/YYYY HH:mm');
						} else {
							return moment(value).local().format('DD/MM/YYYY hh:mm A');
						}
					}
				},
				{
					key: 'departmentName',
					label: this.FormMSG(23, 'Department'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'functionName',
					label: this.FormMSG(24, 'Function'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'actions',
					label: this.FormMSG(52, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			return ret;
		}
	},
	methods: {
		async GetEncodingValidation(xid) {
			let projectId = parseInt(store.projectID(), 10);
			await GetEncodingValidation(projectId, 0, xid).then((result) => {
				this.userEntryLevel = Object.assign({}, result);
			});
		},
		async handleClickEditUserRequest(payload) {
			const userRequestInfo = await getUserRequestInfo(payload.xid);
			this.editData = {
				email: userRequestInfo.email,
				userFirstName: userRequestInfo.userFirstName,
				userName: userRequestInfo.userName,
				language: userRequestInfo.language,
				startDate: userRequestInfo.startDate.split('T')[0],
				startTime: userRequestInfo.startTime,
				endDate: userRequestInfo.endDate.split('T')[0],
				department: userRequestInfo.department,
				functionCategory: userRequestInfo.function,
				function: userRequestInfo.function,
				isAdmin: userRequestInfo.isAdmin,
				isProd: userRequestInfo.isProd,
				canManageDocuments: userRequestInfo.canManageDocuments,
				canManageDocumentPackage: userRequestInfo.canManageDocumentPackage,
				canManageCallSheets: userRequestInfo.canManageCallSheets,
				canManageAccommodations: userRequestInfo.canManageAccommodations,
				canManageLocations: userRequestInfo.canManageLocations,
				canExportImport: userRequestInfo.canExportImport,
				canManageCarbonRemoval: userRequestInfo.canManageCarbonRemoval,
				canManageTipOfTheDay: userRequestInfo.canManageTipOfTheDay,
				// canViewDepartmentBudget: userRequestInfo.canViewDepartmentBudget,
				canManageCrewList: userRequestInfo.canManageCrewList,
				canViewGlobalBudget: userRequestInfo.canViewGlobalBudget,
				canViewConfigurationScreen: userRequestInfo.canViewConfigurationScreen,
				canViewReportingScreens: userRequestInfo.canViewReportingScreens,
				canAccessUsers: userRequestInfo.canAccessUsers,
				canAccessBudget: userRequestInfo.canAccessBudget,
				canAccessConfig: userRequestInfo.canAccessConfig,
				canAccessGreenReporting: userRequestInfo.canAccessGreenReporting,
				canAccessMyBudget: userRequestInfo.canAccessMyBudget,
				canAccessCrewList: userRequestInfo.canAccessCrewList,
				canViewWasteScreens: userRequestInfo.canViewWasteScreens,
				canManageTransport: userRequestInfo.canManageTransport,
				canViewGreenTable: userRequestInfo.canViewGreenTable,
				canAccessOnboarding: userRequestInfo.canAccessOnboarding,
				validationLevel: userRequestInfo.validationLevel,
				daysForeseen: parseInt(userRequestInfo.daysForeseen, 10),
				xid: userRequestInfo.xid,
				gateKeeper: userRequestInfo.gateKeeper,
				productionAccountant: userRequestInfo.productionAccountant,
				upm: userRequestInfo.upm,
				lineProducer: userRequestInfo.lineProducer,
				studio: userRequestInfo.studio,
				canValidateI9: userRequestInfo.canValidateI9,
				canLockBudget: userRequestInfo.canLockBudget,
				canModifyEstimatedFinalCost: userRequestInfo.canModifyEstimatedFinalCost,
				canEditAndAddCode: userRequestInfo.canEditAndAddCode,
				canMarkEntryAsProcessed: userRequestInfo.canMarkEntryAsProcessed,
				useExpenseInvoice: userRequestInfo.useExpenseInvoice,
				canEditPo: userRequestInfo.canEditPo,
				canEditExpense: userRequestInfo.canEditExpense,
				canDeleteAfterValidation: userRequestInfo.canDeleteAfterValidation,
				session1A: userRequestInfo.session1A,
				session1B: userRequestInfo.session1B,
				session2A: userRequestInfo.session2A,
				session2B: userRequestInfo.session2B,
				session3A: userRequestInfo.session3A,
				session3B: userRequestInfo.session3B,
				session4A: userRequestInfo.session4A,
				session4B: userRequestInfo.session4B,
				session5A: userRequestInfo.session5A,
				session5B: userRequestInfo.session5B,
				session6A: userRequestInfo.session6A,
				session6B: userRequestInfo.session6B,
				session7A: userRequestInfo.session7A,
				session7B: userRequestInfo.session7B,
				session8A: userRequestInfo.session8A,
				session8B: userRequestInfo.session8B,
				contractTemplate: {
					defaultDayType: userRequestInfo.defaultDayType,
					dailyRate: userRequestInfo.dailyRate,
					travelDailyRate: userRequestInfo.travelDailyRate,
					hourlyRate: userRequestInfo.hourlyRate,
					overtimeRate: userRequestInfo.overtimeRate,
					nightTimeRate: userRequestInfo.nightTimeRate,
					hourBeforeTimeRate: userRequestInfo.hourBeforeTimeRate,
					kmRate: userRequestInfo.kmRate,
					lunchPerDiem: userRequestInfo.lunchPerDiem,
					hotelPerDiem: userRequestInfo.hotelPerDiem,
					dailyRightTransfer: userRequestInfo.dailyRightTransfer,
					nightTimeStart: userRequestInfo.nightTimeStart,
					nightTimeEnd: userRequestInfo.nightTimeEnd,
					minTimeBetweenDays: userRequestInfo.minTimeBetweenDays,
					lunchMinimum: userRequestInfo.lunchMinimum,
					totMinPerDayStr: userRequestInfo.totMinPerDayStr,
					totMinPerWeekStr: userRequestInfo.totMinPerWeekStr,
					sixthDay: userRequestInfo.sixthDay,
					seventhDay: userRequestInfo.seventhDay,
					timeManagementType: userRequestInfo.timeManagementType,
					weeklyOvertime: userRequestInfo.weeklyOvertime,
					workSixDays: userRequestInfo.workSixDays,
					weeklyFiveDaysMinimumSalary: userRequestInfo.weeklyFiveDaysMinimumSalary,
					weeklySixDaysMinimumSalary: userRequestInfo.weeklySixDaysMinimumSalary,
					weeklyFiveDaysEffNumHours: userRequestInfo.weeklyFiveDaysEffNumHours,
					weeklyFiveDaysEquNumHours: userRequestInfo.weeklyFiveDaysEquNumHours,
					weeklySixDaysEffNumHours: userRequestInfo.weeklySixDaysEffNumHours,
					weeklySixDaysEquNumHours: userRequestInfo.weeklySixDaysEquNumHours,
					weeklyBonusAmount: userRequestInfo.weeklyBonusAmount,
					weeklyFiveDaysRefSalary: userRequestInfo.weeklyFiveDaysRefSalary,
					weeklySixDaysRefSalary: userRequestInfo.weeklySixDaysRefSalary,
					weeklyOvtOneLimit: userRequestInfo.weeklyOvtOneLimit,
					weeklyOvtOneRate: userRequestInfo.weeklyOvtOneRate,
					weeklyOvtTwoLimit: userRequestInfo.weeklyOvtTwoLimit,
					weeklyOvtTwoRate: userRequestInfo.weeklyOvtTwoRate,
					weeklyOvtThreeLimit: userRequestInfo.weeklyOvtThreeLimit,
					weeklyOvtThreeRate: userRequestInfo.weeklyOvtThreeRate,
					weeklyBaseEffNumHours: userRequestInfo.weeklyBaseEffNumHours,
					hourlyRateAnnexThree: userRequestInfo.hourlyRateAnnexThree,
					weeklyBonusAmountFiveDays: userRequestInfo.weeklyBonusAmountFiveDays,
					weeklyBonusAmountSixDays: userRequestInfo.weeklyBonusAmountSixDays,
					weeklyBaseRefSalary: userRequestInfo.weeklyBaseRefSalary,
					weeklyBaseMinimumSalary: userRequestInfo.weeklyBaseMinimumSalary,
					weeklyFiveDaysMinimumSalaryAnThree: userRequestInfo.weeklyFiveDaysMinimumSalaryAnThree,
					weeklySixDaysMinimumSalaryAnThree: userRequestInfo.weeklySixDaysMinimumSalaryAnThree,
					transportRate: userRequestInfo.transportRate,
					transportPaidAfter: userRequestInfo.transportPaidAfter,
					ovtLimit1: userRequestInfo.ovtLimit1,
					ovtLimit2: userRequestInfo.ovtLimit2,
					ovtRate1: userRequestInfo.ovtRate1,
					ovtRate2: userRequestInfo.ovtRate2,
					sundayPublicHolidayFactor: userRequestInfo.sundayPublicHolidayFactor,
					companyCostFactor: userRequestInfo.companyCostFactor,
					minTimeBetweenWeek: userRequestInfo.minTimeBetweenWeek,
					transportPaidAfterStr: userRequestInfo.transportPaidAfterStr,
					ovtLimit1Str: userRequestInfo.ovtLimit1Str,
					ovtLimit2Str: userRequestInfo.ovtLimit2Str,
					minTimeBetweenWeekStr: userRequestInfo.minTimeBetweenWeekStr,
					totMinPerDayForContDay: userRequestInfo.totMinPerDayForContDay,
					totMinPerDayForContDayStr: userRequestInfo.totMinPerDayForContDayStr,
					lunchMinimumForContDay: userRequestInfo.lunchMinimumForContDay,
					weeklyBaseEffNumHoursStr: userRequestInfo.weeklyBaseEffNumHoursStr,
					weeklyOvtOneLimitStr: userRequestInfo.weeklyOvtOneLimitStr,
					weeklyOvtTwoLimitStr: userRequestInfo.weeklyOvtTwoLimitStr,
					weeklyOvtThreeLimitStr: userRequestInfo.weeklyOvtThreeLimitStr,
					lunchMinimumStr: userRequestInfo.lunchMinimumStr,
					lunchMinimumForContDayStr: userRequestInfo.lunchMinimumForContDayStr,
					employeeStatus: userRequestInfo.employeeStatus,
					isAutomaticSentContract: userRequestInfo.isAutomaticSentContract,
					costCenterPrepsType: userRequestInfo.costCenterPrepsType,
					costCenterShootingType: userRequestInfo.costCenterShootingType,
					costCenterWrapType: userRequestInfo.costCenterWrapType,
					documentPackageId: userRequestInfo.documentPackageId,
					useCarAllowance: userRequestInfo.useCarAllowance,
					carAllowanceRate: userRequestInfo.carAllowanceRate,
					carAllowance: userRequestInfo.carAllowance,
					useProductionFeeAllowance: userRequestInfo.useProductionFeeAllowance,
					productionFeeAllowanceRate: userRequestInfo.productionFeeAllowanceRate,
					productionFeeAllowance: userRequestInfo.productionFeeAllowance,
					useDinnerPerDiem: userRequestInfo.useDinnerPerDiem,
					dinnerPerDiem: userRequestInfo.dinnerPerDiem,
					useComputerAllowance: userRequestInfo.useComputerAllowance,
					computerAllowanceRate: userRequestInfo.computerAllowanceRate,
					computerAllowance: userRequestInfo.computerAllowance,
					minimumHourGuaranteed: userRequestInfo.minimumHourGuaranteed,
					minimumHourGuaranteedType: userRequestInfo.minimumHourGuaranteedType,
					minimumHourGuaranteedStr: userRequestInfo.minimumHourGuaranteedStr,
					beforeCallTimeRate: userRequestInfo.beforeCallTimeRate,
					ifWorkAboveDailyHours: userRequestInfo.ifWorkAboveDailyHours,
					usePhoneAllowance: userRequestInfo.usePhoneAllowance,
					phoneAllowanceRate: userRequestInfo.phoneAllowanceRate,
					phoneAllowance: userRequestInfo.phoneAllowance,
					useBoxKitAllowance: userRequestInfo.useBoxKitAllowance,
					boxKitAllowanceRate: userRequestInfo.boxKitAllowanceRate,
					boxKitAllowance: userRequestInfo.boxKitAllowance,
					useAbroadPerDiem: userRequestInfo.useAbroadPerDiem,
					abroadPerDiem: userRequestInfo.abroadPerDiem,
					useHotelPerDiem: userRequestInfo.useHotelPerDiem,
					useLunchPerDiem: userRequestInfo.useLunchPerDiem,
					note: userRequestInfo.note,
					weeklyFiveDaysEffNumHoursStr: userRequestInfo.weeklyFiveDaysEffNumHoursStr,
					weeklyFiveDaysEquNumHoursStr: userRequestInfo.weeklyFiveDaysEquNumHoursStr,
					weeklySixDaysEffNumHoursStr: userRequestInfo.weeklySixDaysEffNumHoursStr,
					contractReference: userRequestInfo.contractReference,
					contractType: userRequestInfo.contractType,
					dailyOvertime: userRequestInfo.dailyOvertime,
					ovtLimit: userRequestInfo.ovtLimit,
					ovtLimitContinuousDay: userRequestInfo.ovtLimitContinuousDay,
					ovtLimitContinuousDay1: userRequestInfo.ovtLimitContinuousDay1,
					ovtLimitContinuousDay2: userRequestInfo.ovtLimitContinuousDay2,
					ovtLimitStr: userRequestInfo.ovtLimitStr,
					ovtLimitContinuousDayStr: userRequestInfo.ovtLimitContinuousDayStr,
					ovtLimitContinuousDay1Str: userRequestInfo.ovtLimitContinuousDay1Str,
					ovtLimitContinuousDay2Str: userRequestInfo.ovtLimitContinuousDay2Str,
					monthlyRate: parseFloat(userRequestInfo.monthlyRate),
					useTravelDailyRate: userRequestInfo.useTravelDailyRate,
					baseSalary1PayCode: userRequestInfo.baseSalary1PayCode === 0 ? null : +userRequestInfo.baseSalary1PayCode,
					baseSalary2PayCode: userRequestInfo.baseSalary2PayCode === 0 ? null : +userRequestInfo.baseSalary2PayCode,
					baseSalary3PayCode: userRequestInfo.baseSalary3PayCode === 0 ? null : +userRequestInfo.baseSalary3PayCode,
					baseSalary4PayCode: userRequestInfo.baseSalary4PayCode === 0 ? null : +userRequestInfo.baseSalary4PayCode,
					dayOvt1PayCode: userRequestInfo.dayOvt1PayCode === 0 ? null : +userRequestInfo.dayOvt1PayCode,
					dayOvt2PayCode: userRequestInfo.dayOvt2PayCode === 0 ? null : +userRequestInfo.dayOvt2PayCode,
					dayOvt3PayCode: userRequestInfo.dayOvt3PayCode === 0 ? null : +userRequestInfo.dayOvt3PayCode,
					weekOvt1PayCode: userRequestInfo.weekOvt1PayCode === 0 ? null : +userRequestInfo.weekOvt1PayCode,
					weekOvt2PayCode: userRequestInfo.weekOvt2PayCode === 0 ? null : +userRequestInfo.weekOvt2PayCode,
					weekOvt3PayCode: userRequestInfo.weekOvt3PayCode === 0 ? null : +userRequestInfo.weekOvt3PayCode,
					nightTimePayCode: userRequestInfo.nightTimePayCode === 0 ? null : +userRequestInfo.nightTimePayCode,
					restPayCode: userRequestInfo.restPayCode === 0 ? null : +userRequestInfo.restPayCode,
					transportPayCode: userRequestInfo.transportPayCode === 0 ? null : +userRequestInfo.transportPayCode,
					sundayPayCode: userRequestInfo.sundayPayCode === 0 ? null : +userRequestInfo.sundayPayCode,
					seventhDayPayCode: userRequestInfo.seventhDayPayCode === 0 ? null : +userRequestInfo.seventhDayPayCode,
					sixthDayPayCode: userRequestInfo.sixthDayPayCode === 0 ? null : +userRequestInfo.sixthDayPayCode,
					beforeTimePayCode: userRequestInfo.beforeTimePayCode === 0 ? null : +userRequestInfo.beforeTimePayCode,
					travelAllowancePayCode: userRequestInfo.travelAllowancePayCode === 0 ? null : +userRequestInfo.travelAllowancePayCode,
					carAllowancePayCode: userRequestInfo.carAllowancePayCode === 0 ? null : +userRequestInfo.carAllowancePayCode,
					phoneAllowancePayCode: userRequestInfo.phoneAllowancePayCode === 0 ? null : +userRequestInfo.phoneAllowancePayCode,
					computerAllowancePayCode: userRequestInfo.computerAllowancePayCode === 0 ? null : +userRequestInfo.computerAllowancePayCode,
					boxKitAllowancePayCode: userRequestInfo.boxKitAllowancePayCode === 0 ? null : +userRequestInfo.boxKitAllowancePayCode,
					productionFeeAllowancePayCode: userRequestInfo.productionFeeAllowancePayCode === 0 ? null : +userRequestInfo.productionFeeAllowancePayCode,
					lunchPerDiemPayCode: userRequestInfo.lunchPerDiemPayCode === 0 ? null : +userRequestInfo.lunchPerDiemPayCode,
					dinerPerDiemPayCode: userRequestInfo.dinerPerDiemPayCode === 0 ? null : +userRequestInfo.dinerPerDiemPayCode,
					hotelPerDiemPayCode: userRequestInfo.hotelPerDiemPayCode === 0 ? null : +userRequestInfo.hotelPerDiemPayCode,
					abroadPerDiemPayCode: userRequestInfo.abroadPerDiemPayCode === 0 ? null : +userRequestInfo.abroadPerDiemPayCode,
					collectiveAgreement: userRequestInfo.collectiveAgreement,
					echelon: userRequestInfo.echelon,
					analyticCode: userRequestInfo.analyticCode,
					publicHolidayCalendar: userRequestInfo.publicHolidayCalendar === 0 ? null : +userRequestInfo.publicHolidayCalendar
				}
			};
			await this.GetEncodingValidation(userRequestInfo.xid);
			// console.log({editData: this.editData});
			this.showUserModal = true;
		},
		handleUserFormSuccess() {
			this.showUserModal = false;
			this.$emit('invitation-list:success');
		},
		handleCloseForm() {
			this.toggleUserModal();
		},
		toggleUserModal() {
			this.showUserModal = !this.showUserModal;
		},
		okAfterUserEdit() {
			this.handleCloseForm();
			this.$emit('invitation-list:ok-after-user-edit');
		},
		handleChangeFilter(payload) {
			this.users = [];
			let filter = payload.trim().toLowerCase();
			if (filter === '') {
				this.filter = '';
				this.users = this.userRequests;
			} else {
				this.users = this.userRequests;
				let arr = this.users.filter((u) => {
					return (
						u.userName.toLowerCase().indexOf(filter) > -1 ||
						u.userFirstName.toLowerCase().indexOf(filter) > -1 ||
						u.email.toLowerCase().indexOf(filter) > -1 ||
						u.departmentName.toLowerCase().indexOf(filter) > -1 ||
						u.functionName.toLowerCase().indexOf(filter) > -1
					);
				});

				this.filter = filter;
				this.users = arr;
			}
		},
		handleClickDeleteUserRequest(payload) {
			const action = () => {
				this.$emit('invitation-list:delete', payload);
			};

			this.confirmModal(action, this.FormMSG(40, 'Are you sure ?'));
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['update-invitation'].$refs['submit'].click();
		},
		handleWatingAddUpdProject(payload) {
			this.watingAddProject = payload;
		}
	}
};
</script>
