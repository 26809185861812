import { render, staticRenderFns } from "./Invitations.vue?vue&type=template&id=6f7f2244&"
import script from "./Invitations.vue?vue&type=script&lang=js&"
export * from "./Invitations.vue?vue&type=script&lang=js&"
import style0 from "./Invitations.vue?vue&type=style&index=0&id=6f7f2244&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tgs-automation/tgs-automation/mojaweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f7f2244')) {
      api.createRecord('6f7f2244', component.options)
    } else {
      api.reload('6f7f2244', component.options)
    }
    module.hot.accept("./Invitations.vue?vue&type=template&id=6f7f2244&", function () {
      api.rerender('6f7f2244', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/onboarding/components/Invitations.vue"
export default component.exports