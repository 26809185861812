var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "btn-invite" },
        [
          _c("invite-user", {
            attrs: { "project-id": _vm.projectId },
            on: { "invite-user:success": _vm.handleInviteUserSuccess },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("invitation-list", {
                attrs: {
                  "project-id": _vm.projectId,
                  "user-requests": _vm.userRequests,
                },
                on: {
                  "invitation-list:success": _vm.handleInvitationListSuccess,
                  "invitation-list:delete": _vm.handleInvitationListDelete,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }